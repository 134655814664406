//import bcBoxLayouts from './bcBoxLayouts.js';
//import vcBoxLayouts from './vcBoxLayouts.js';
//import pBoxLayouts from './postersBoxLayouts.js';
//import sBoxLayouts from './signageBoxLayouts.js';

//const boxLayouts=sBoxLayouts;//bcBoxLayouts.concat(vcBoxLayouts,pBoxLayouts,sBoxLayouts);

function splitBox(w, h, x, y, vOrH, s) {
  var boxes={box1:{}, box2:{}};
  if (vOrH==1) {
    boxes.box1.W=w*s;
    boxes.box1.H=h;
    boxes.box1.X=x;
    boxes.box1.Y=y;
    boxes.box2.W=w*(1-s);
    boxes.box2.H=h;
    boxes.box2.X=x+w*s;
    boxes.box2.Y=y;
  } else {
    boxes.box1.W=w;
    boxes.box1.H=h*s;
    boxes.box1.X=x;
    boxes.box1.Y=y;
    boxes.box2.W=w;
    boxes.box2.H=h*(1-s);
    boxes.box2.X=x;
    boxes.box2.Y=y+h*s;
  }
  return boxes;
}



function getMinMaxLen(text) {

  var words=text.split(' ');
  var index=0;
  var maxLen=0;
  var minLen=100;
  for (let i = 0; i < words.length; i++) {
    if (words[i].length>maxLen) {maxLen=words[i].length;index=i;}
    if (words[i].length<minLen) {minLen=words[i].length;}

  }


  return [minLen, maxLen];

}

function getNumAuthors(au) {


au=(au.split( /,/ ).reduce( (a,b, i) => i %2 == 0 ? a + ";" + b : a + "," + b ) );
var authors=au.split(';');
const numAuthors=authors.length;
var authors2=[];
authors.forEach(au => {
  var temp=au.split(',');

  var auTemp='';
  if (temp[1]) {auTemp=temp[1].trim()+' '+temp[0].trim()} else {auTemp=temp[0].trim()}
  authors2.push(auTemp.trim());

});
au=authors2.join(', ');
return [au, numAuthors];

}

export default function genDesign(title,canvasWidth, canvasHeight,orientation,numElements) {
  var design={};
  const textCases=['upper'];//['proper','proper','upper'];//['upper', 'proper','lower'];//,'proper'];//['upper','proper'];//lower
  const tiFontSizes=[120,90,72,60, 48, 42,36,32,28,24,18];//[120,90,72,60, 48, 42,36,32,28,24,18,14,12];//Training --- [120,90, 72,60,48,42,36,32,24, 28];//120,90
  const stiFontSizes=[48, 42,36,32,28,24,18,14,12];//[42, 36, 32, 24, 20, 18, 16];//Training --- [24];//[24]
  const auFontSizes=[32 ,24, 18, 14];//Training --- [18];//[18]
  const textAlignments=['left', 'center'];
  const textDirection=['up'];//['up','down','middle'];

  const fontCombos=[
    {id:"CormonrantGaramond", tiFont: "Cormorant Garamond", stiFont:"Cormorant Garamond", auFont:"Cormorant Garamond"},
    {id:"MontessaratFiraCode", tiFont: "Montserrat", stiFont:"Fira Code", auFont:"Fira Code"},
     {id:"OswaldQuattrocento", tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},
     {id:"cyborg", tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
     {id: "LoraRoboto", tiFont: "Lora", stiFont:"Roboto", auFont:"Roboto"},
     {id: "PlayfairDisplaySourceSansPro", tiFont: "Playfair Display", stiFont:"Source Sans Pro", auFont:"Source Sans Pro"},
     {id: "Neucha", tiFont: "Neucha", stiFont:"Neucha", auFont:"Neucha"},
     {id: "News", tiFont: "News Cycle", stiFont:"News", auFont:"News"},
     {id: "lux", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},
     {id: "sketch", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},
     {id: "expletus", tiFont: "Expletus Sans", stiFont:"Hind", auFont:"Hind"},
      {id: "Bubblegum", tiFont: "Bubblegum Sans", stiFont:"Open Sans", auFont:"Open Sans"},
      {id: "Mulish", tiFont: "Mulish", stiFont:"Space Mono", auFont:"Space Mono"},
      
   ];

  const fontEffects=["regular"];//["regular", "regular", "regular","bold","italic"];//,"regular","regular", "bold","bold", "italic"];
  const dimsArray=[
    {cW: 420, cH: 594},
    {cW: 560, cH: 860},
    {cW: 460, cH: 610},
    {cW: 610, cH: 910},

  ];
  const dims=dimsArray[Math.floor(Math.random()*dimsArray.length)];
  design.canvasWidth=canvasWidth;
  design.canvasHeight=canvasHeight;
  
  design.ti=title.title.trim();
  design.sti=title.subtitle.trim();
  design.au=title.au.trim();

  




var boxLayout={ti:{},sti:{},au:{}};

const layouts=[
 {id:"left", numElements:3, tiAlign:'left', stiAlign:'left', auAlign: 'left',
  tiDirection:'middle', stiDirection: 'down', auDirection:'up',
  tiCase: 'upper', stiCase: 'lower', auCase: 'proper',

  splitRatios: [0.95,0.9],
  wSplitRatios: [0.95, 0.9],
  hSplitRatios: [0.8, 0.9,  0.85],
  vOrH: 0,
  hVOrH: 0,
  marginStarts: [0.2,0.25],
  marginEnds: [ 0.1],
  marginTops: [ 0.05],
  marginBottoms: [0.05 ],
  },

  {id:"tallCenter", numElements:3, tiAlign:'center', stiAlign:'center', auAlign: 'center',
    tiDirection:'middle', stiDirection: 'down', auDirection:'up',
    tiCase: 'upper', stiCase: 'lower', auCase: 'proper',

    splitRatios: [0.9, 0.95],
    wSplitRatios: [0.15, 0.2, 0.25],
    hSplitRatios: [0.75, 0.8, 0.85],
    vOrH: 0,
    hVOrH: 0,
    symmetric: 1,
    marginStarts: [ 0.01, 0.05, 0.15, 0.3],
    marginEnds: [],
    marginTops: [0.01],
    marginBottoms: [],
    },


    {id:"shortCenter", numElements:3, tiAlign:'center', stiAlign:'center', auAlign: 'center',
      tiDirection:'middle', stiDirection: 'middle', auDirection:'middle',
      tiCase: 'upper', stiCase: 'lower', auCase: 'proper',

      splitRatios: [0.65, 0.7, 0.75],
      wSplitRatios: [1],
      hSplitRatios: [0.75, 0.8, 0.85],
      vOrH: 0,
      hVOrH: 0,
      symmetric: 1,
      marginStarts: [ 0.15, 0.3],
      marginEnds: [],
      marginTops: [0.3, 0.25, 0.2],
      marginBottoms: [],
      },

      {id:"tallRight", numElements:3, tiAlign:'right', stiAlign:'right', auAlign: 'right',
        tiDirection:'down', stiDirection: 'up', auDirection:'up',
        tiCase: 'upper', stiCase: 'lower', auCase: 'proper',

        splitRatios: [0.95, 0.9],
        wSplitRatios: [0.95, 0.9],
        hSplitRatios: [0.9, 0.85],
        vOrH: 0,
        hVOrH: 0,
        marginStarts: [0.4, 0.45, 0.5],
        marginEnds: [ 0.05],
        marginTops: [ 0.05],
        marginBottoms: [0.05 ],
        },

        {id:"smallBox", numElements:3, tiAlign:'left', stiAlign:'left', auAlign: 'left',
          tiDirection:'middle', stiDirection: 'down', auDirection:'up',
          tiCase: 'upper', stiCase: 'lower', auCase: 'proper',
          splitRatios: [0.9, 0.85, 0.8],
          wSplitRatios: [1],
          hSplitRatios: [0.7, 0.75, 0.8],
          vOrH: 0,
          hVOrH: 0,
          marginStarts: [0.3],
          marginEnds: [ 0.2],
          marginTops: [ 0.3],
          marginBottoms: [0.2 ],
          },

          {id:"bigAuthor", numElements:3, tiAlign:'center', stiAlign:'center', auAlign: 'center',
            tiDirection:'middle', stiDirection: 'down', auDirection:'up',
            tiCase: 'upper', stiCase: 'lower', auCase: 'upper',
            splitRatios: [0.8],
            wSplitRatios: [0.3],
            hSplitRatios: [0.7, 0.75, 0.8],
            vOrH: 0,
            hVOrH: 0,
            marginStarts: [0.1],
            marginEnds: [ 0.1],
            marginTops: [ 0.1],
            marginBottoms: [ 0.1 ],
            },


];

const layout=layouts[Math.floor(Math.random()*layouts.length)];

design.vOrH=layout.vOrH;
design.hVOrH=layout.hVOrH;
design.wVOrH=layout.wVOrH;

design.tiAlign=layout.tiAlign;
design.tiDirection=layout.tiDirection;

design.stiAlign=layout.stiAlign;
design.stiDirection=layout.stiDirection;

design.auAlign=layout.auAlign;
design.auDirection=layout.auDirection;

design.tiCase=layout.tiCase;
design.stiCase=layout.stiCase;
design.auCase=layout.auCase;


const splitRatios=[0.7, 0.8, 0.85, 0.9, 0.95];
//const splitRatios=[0.5,0.4,0.6];
const margins=[0.01, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3 ];
//const margins=[0.4];

//design.splitRatio=layout.splitRatios[Math.floor(Math.random()*layout.splitRatios.length)];
design.marginStart=layout.marginStarts[Math.floor(Math.random()*layout.marginStarts.length)];
if (layout.marginEnds.length > 0) {
  design.marginEnd=layout.marginEnds[Math.floor(Math.random()*layout.marginEnds.length)]
} else {
  design.marginEnd=design.marginStart;
}
design.marginTop=layout.marginTops[Math.floor(Math.random()*layout.marginTops.length)];
if (layout.marginBottoms.length > 0 ) {
  design.marginBottom=layout.marginBottoms[Math.floor(Math.random()*layout.marginBottoms.length)];
} else {
  design.marginBottom=design.marginTop;
}


design.hPaddingStart=0;
design.hPaddingEnd=0;
design.hPaddingTop=0;
design.hPaddingBottom=0;


design.tiPaddingStart=0;
design.tiPaddingEnd=0;
design.tiPaddingTop=0;
design.tiPaddingBottom=0;

design.stiPaddingStart=0;
design.stiPaddingEnd=0;
design.stiPaddingTop=0;
design.stiPaddingBottom=0;

design.auPaddingStart=0;
design.auPaddingEnd=0;
design.auPaddingTop=0;
design.auPaddingBottom=0;




var boxes=splitBox(1-design.marginStart-design.marginEnd, 1-design.marginTop-design.marginBottom, 0+design.marginStart, 0+design.marginTop, design.vOrH, layout.splitRatios[Math.floor(Math.random()*layout.splitRatios.length)]);

boxLayout.id=layout.id;
boxLayout.ti=boxes.box1;
boxLayout.au=boxes.box2;
//design.splitRatio=layout.wSplitRatios[Math.floor(Math.random()*layout.wSplitRatios.length)];

var boxes2=splitBox(boxLayout.ti.W, boxLayout.ti.H, boxLayout.ti.X, boxLayout.ti.Y, design.wVOrH, layout.wSplitRatios[Math.floor(Math.random()*layout.wSplitRatios.length)]);
boxLayout.ti=boxes2.box1;

//design.splitRatio=layout.hSplitRatios[Math.floor(Math.random()*layout.hSplitRatios.length)];

var boxes3=splitBox(boxLayout.ti.W-design.hPaddingStart-design.hPaddingEnd, boxLayout.ti.H-design.hPaddingTop-design.hPaddingBottom, boxLayout.ti.X+design.hPaddingStart, boxLayout.ti.Y+design.hPaddingTop, design.hVOrH, layout.hSplitRatios[Math.floor(Math.random()*layout.hSplitRatios.length)]);
boxLayout.ti=boxes3.box1;
boxLayout.sti=boxes3.box2;



 const fontCombo=fontCombos[Math.floor(Math.random()*fontCombos.length)];
 design.tiFont=fontCombo.tiFont;
 design.stiFont=fontCombo.stiFont;
 design.suFont=fontCombo.auFont;

  //design.tiAlign=boxLayout.ti.align;//textAlignments[Math.floor(Math.random()*textAlignments.length)];
  //design.stiAlign=boxLayout.sti.align;
  //design.auAlign='right';//boxLayout.au.align;
  design.tiFontEffect='bold';//'regular';//'bold';//fontEffects[Math.floor(Math.random()*fontEffects.length)];


  design.tiFontSize=tiFontSizes[Math.floor(Math.random()*tiFontSizes.length)]
  design.stiFontEffect='regular';//fontEffects[Math.floor(Math.random()*fontEffects.length)];
  design.stiFontSize=stiFontSizes[Math.floor(Math.random()*stiFontSizes.length)]
  design.auFontEffect=fontEffects[Math.floor(Math.random()*fontEffects.length)];
  design.auFontSize=auFontSizes[Math.floor(Math.random()*auFontSizes.length)]

  design.boxLayoutId=boxLayout.id;
  //design.p=boxLayout.p;

 


  design.tiX=Math.floor(boxLayout.ti.X*design.canvasWidth);//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.ti.X*design.canvasWidth);
  //const tiYIndex=Math.floor(Math.random()*ys.length);
  design.tiY=Math.floor(boxLayout.ti.Y*design.canvasHeight);//ys[tiYIndex]*design.canvasHeight;//Math.floor(boxLayout.ti.Y*design.canvasHeight);
  //design.tiDirection=boxLayout.ti.direction;//'middle';//boxLayout.ti.direction;
  design.tiWidth=Math.floor(boxLayout.ti.W*design.canvasWidth);//design.canvasWidth-design.tiX;//Math.floor(boxLayout.ti.W*design.canvasWidth);
  design.tiHeight=Math.floor(boxLayout.ti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.ti.H*design.canvasHeight);
  design.tiX+=Math.floor(design.tiPaddingStart*design.tiWidth);//design.p/2*design.tiWidth;
  design.tiY+=Math.floor(design.tiPaddingTop*design.tiHeight);//design.p/2*design.tiHeight;
  design.tiWidth-=Math.floor((design.tiPaddingStart+design.tiPaddingEnd)*design.tiWidth);//design.p*design.tiWidth;
  design.tiHeight-=Math.floor((design.tiPaddingTop+design.tiPaddingBottom)*design.tiHeight);//design.p*design.tiHeight;



  design.stiX=Math.floor(boxLayout.sti.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.sti.X*design.canvasWidth);
  //ys.splice(tiYIndex,1);
  //const stiYIndex=Math.floor(Math.random()*ys.length);
  design.stiY=Math.floor(boxLayout.sti.Y*design.canvasHeight);//ys[stiYIndex]*design.canvasHeight;//Math.floor(boxLayout.sti.Y*design.canvasHeight);
  //design.stiDirection=boxLayout.sti.direction;//'middle';//boxLayout.sti.direction;
  design.stiWidth=Math.floor(boxLayout.sti.W*design.canvasWidth);//design.canvasWidth-design.stiX;//Math.floor(boxLayout.sti.W*design.canvasWidth);
  design.stiHeight=Math.floor(boxLayout.sti.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.sti.H*design.canvasHeight);
  design.stiX+=Math.floor(design.stiPaddingStart*design.stiWidth);//design.p/2*design.stiWidth;
  design.stiY+=Math.floor(design.stiPaddingTop*design.stiHeight);//design.p/2*design.stiHeight;
  design.stiWidth-=Math.floor((design.stiPaddingStart+design.stiPaddingEnd)*design.stiWidth);//design.p*design.stiWidth;
  design.stiHeight-=Math.floor((design.stiPaddingTop+design.stiPaddingBottom)*design.stiHeight);//design.p*design.stiHeight;

  //ys.splice(stiYIndex,1);

  design.auX=Math.floor(boxLayout.au.X*design.canvasWidth);//design.tiX;//xs[Math.floor(Math.random()*3)]*design.canvasWidth;//Math.floor(boxLayout.au.X*design.canvasWidth);
  design.auY=Math.floor(boxLayout.au.Y*design.canvasHeight);//ys[Math.floor(Math.random()*ys.length)]*design.canvasHeight;//Math.floor(boxLayout.au.Y*design.canvasHeight);
  //design.auDirection='up';//boxLayout.au.direction;//'middle';//boxLayout.au.direction;
  design.auWidth=Math.floor(boxLayout.au.W*design.canvasWidth);//design.canvasWidth-design.auX;//Math.floor(boxLayout.au.W*design.canvasWidth);
  design.auHeight=Math.floor(boxLayout.au.H*design.canvasHeight);//design.canvasHeight/4;//Math.floor(boxLayout.au.H*design.canvasHeight);
  design.auX+=Math.floor(design.auPaddingStart*design.auWidth);//design.p/2*design.stiWidth;
  design.auY+=Math.floor(design.auPaddingTop*design.auHeight);//design.p/2*design.stiHeight;
  design.auWidth-=Math.floor((design.auPaddingStart+design.auPaddingEnd)*design.auWidth);//design.p*design.stiWidth;
  design.auHeight-=Math.floor((design.auPaddingTop+design.auPaddingBottom)*design.auHeight);


  design.label='bad';


 
  if (design.tiCase==='upper') design.ti = title.title.trim().toUpperCase();
  if (design.tiCase==='lower') design.ti = title.title.trim().toLowerCase();

  if (design.stiCase==='upper') design.sti = title.subtitle.trim().toUpperCase();
  if (design.stiCase==='lower') design.sti = title.subtitle.trim().toLowerCase();

  if (design.auCase==='upper') design.au = title.au.trim().toUpperCase();
  if (design.auCase==='lower') design.au = title.au.trim().toLowerCase();

  [design.tiMinWordLength, design.tiMaxWordLength] = getMinMaxLen(design.ti);
  [design.stiMinWordLength, design.stiMaxWordLength] = getMinMaxLen(design.sti);
  [design.auMinWordLength, design.auMaxWordLength] = getMinMaxLen(design.au);

  design.tiNumWords=design.ti.split(' ').length;
  design.stiNumWords=design.sti.split(' ').length;
  design.auNumWords=design.au.split(' ').length;

  design.tiLength=design.ti.length;
  design.stiLength=design.sti.length;
  design.auLength=design.au.length;

  if (design.stiLength ==0) {
  design.stiX=0;
  design.stiY=0;
  design.stiWidth=0;
  design.stiHeight=0;
  design.stiAlign='NA';
  design.stiDirection='NA';
  design.stiFontSize=0;
  }


  if (design.auLength == 0 ) {
  design.auX=0;
  design.auY=0;
  design.auWidth=0;
  design.auHeight=0;
  design.auAlign='NA';
  design.auDirection='NA';
  design.auFontSize=0;
  }
  [design.au, design.numAuthors] = getNumAuthors(design.au);
  //console.log(design.au);

  return design;

}

