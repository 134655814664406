function splitLines3(context, text, maxWidth, separator, direction) {

  //console.log(text, maxWidth, direction);

  var words=text.split(separator);
  var wordsRemaining=[];
  var fits=false;
  var tempLine='';
  var tempMetricWidth=0;
  var tempMetricHeight=0;
  var lines=[];
  var lineLengths=[];
  var metricWidths=[];
  var metricHeights=[];

  var x=1;
  var i=0;
  var tempWord='';
  while (words.length > 0 && x < 10) {
    i=0;
    fits=false;
	  var wL=words.length;
    while (!fits && i < wL) { 
      if (i>0) {
        if (direction=='down' || direction=='middle') { 
	        tempWord=words.pop();
          wordsRemaining.unshift(tempWord);
        } else {
          tempWord=words.shift();
          wordsRemaining.push(tempWord);
        }
      }
      tempLine=words.join(separator);	
      var metrics = context.measureText(tempLine);
      tempMetricWidth=metrics.width;
      tempMetricHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
      if (metrics.width <= maxWidth*1) {
        fits=true;
      } 
      i++;
       tempWord='';
    }
    if (direction=='down' || direction=='middle') {
	    lines.push(tempLine);
      lineLengths.push(tempLine.length);
      metricWidths.push(tempMetricWidth);
      metricHeights.push(tempMetricHeight);
    } else {
	    lines.unshift(tempLine);
      lineLengths.unshift(tempLine.length);

      metricWidths.unshift(tempMetricWidth);
      metricHeights.unshift(tempMetricHeight);
    }
    words=wordsRemaining;
    wordsRemaining=[];
	x++;
  }

  return [lines, metricWidths, metricHeights, lineLengths];

}



// progressively split a string into required num lines
function splitLines2 (text, numLines, separator) {

// split words using space as separator, maybe pass separator as param
var words=text.split(separator);

// find number of words;
var wordsLen=words.length;

// while num of words is more than required num Lines
while (wordsLen > numLines) {
  var minLen=text.length;
  var minIndex=0;

  // find the two consecutive strings that will result in the least length
  for (let i=0; i < wordsLen -1; i++) {
    var newLen=words[i].length+words[i+1].length;
    if (newLen < minLen) {minLen=newLen; minIndex=i}
  }

  // combine two strings found above to reduce the number of lines
  words[minIndex]=words[minIndex]+separator+words[minIndex+1];
  words.splice(minIndex+1,1);
  wordsLen-=1;
  //console.log(words);

};
return words;//console.log(words);

}


export default function wrapText(context, text, x, y, line_width, line_height, direction, alignment, height, separator, ifPrint)
{

    var lines=[];
    var words = text.split(separator);

  var allLinesFit=false;
  var numLines=1;
  var aResult=[];
  var metricWidths=[];
  var metricHeights=[];
  var lineLengths=[];


  [aResult,metricWidths, metricHeights, lineLengths]=splitLines3(context, text, line_width, separator,direction);

  // increment num lines till all lines fit the given bwidth
/*
  do {
    if (numLines ===1) {
      aResult= splitLines2(text.trim(), numLines, separator);
    } else {
      aResult=splitLines2(text.trim(), numLines, separator);
    }

   if (aResult !== false) {
        var noFit=false;
        for (let i=0; i < aResult.length; i++) {
          var metrics = context.measureText(aResult[i]);
          var testWidth = metrics.width;
          if (testWidth > line_width) {noFit=true;}
        }
      allLinesFit=!noFit;
    } else {console.log("error in splitting"); allLinesFit=true;}

    numLines+=1;

  } while (!allLinesFit && numLines <= words.length);

*/
    lines=aResult;

    if (direction==="down") context.textBaseline = 'hanging';
    else if (direction==="up") {
        context.textBaseline = 'bottom';
        y=y+height-(lines.length-1)*line_height;
    } else {
        context.textBaseline = 'middle';
        y=y+height/2-((lines.length-1)/2)*line_height;
    }

    if (alignment==='center') {
      x+=Math.floor(line_width/2);
    } else if (alignment==='right') {
      x+=Math.floor(line_width);
    }

    var maxWidth=0;

    for (var k=0; k<lines.length; k++) {
      var lineWidth = context.measureText(lines[k]).width;
      if (lineWidth > maxWidth) maxWidth=lineWidth;
        context.fillText(lines[k], x, y);
      y+= line_height
    }

    return [lines.length, Math.floor(maxWidth),lines,metricWidths, metricHeights, lineLengths];
}
