import { Route, Switch } from "react-router-dom";
import React, {Suspense} from "react";
import Spinner from './containers/Spinner.js';
//import './App.css';
import WebContainer from './containers/WebContainer.js';
import VCProduct from './containers/VCproduct.js';
//import BCProduct from './containers/BCproduct.js';
//import SignageProduct from './containers/SignageProduct.js';
//import PosterProduct from './containers/PosterProduct.js';
import './style.scss';
import "../node_modules/bootstrap/js/dist/collapse.js";

const App2 = React.lazy(() => import('./App2.js'));
//const WebContainer = React.lazy(() => import('./containers/WebContainer.js'));
const NotFound = React.lazy(() => import('./containers/NotFound.js'));
const Auth = React.lazy(() => import('@aws-amplify/auth'));
//const Routes = React.lazy(() => import('./Routes.js'));
//const VCProduct = React.lazy(() => import ('./containers/VCproduct'));
//const BCProduct = React.lazy(() => import ('./containers/BCproduct'));
//const SignageProduct = React.lazy(() => import ('./containers/SignageProduct'));
//const PosterProduct = React.lazy(() => import ('./containers/PosterProduct'));

function App() {

return (

  /*<VCProduct />*/

  /*<Suspense fallback={<Spinner height="100vh"/>}>
    <Route exact path="/">
    </Route>
  
  </Suspense>*/

    <Suspense fallback={<Spinner height="100vh"/>}>
      <Switch>
        <Route exact path="/404" status={404}>
          <NotFound />
        </Route>
       {/* <Route path="/xdent">
          <App2 />
        </Route> */} 
        <Route path="/web">
          <App2 />
        </Route>  
        <Route path="/web/admin">  
          <App2 />
        </Route>
        <Route exact path="/web/:Id">
      	  <WebContainer />
        </Route>
        <Route path="/web/admin/*">
          <App2 />
        </Route> 
        <Route path="/admin/*">
          <App2 />
        </Route>   
        <Route exact path="/">
          <VCProduct />
        </Route>
      </Switch>
    </Suspense>

);


}

export default App;
