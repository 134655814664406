import wrapText from './wrapText.js';
import galleryImages from './galleryImages2.js';
import ColorThief from '../../node_modules/colorthief/dist/color-thief.mjs'

function getStandardDeviation (array) {
  const n = array.length
  const mean = array.reduce((a, b) => a + b) / n
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}

function textArranger(context, text, fontSize, boxX, boxY, boxW, boxH, direction, alignment, separator, maxNumLines=3) {

console.log(fontSize, boxX, boxY, boxW, boxH, direction, alignment);
    var words = text.split(separator);
    const numWords=words.length;
    const numLines=randomIntFromInterval(numWords === 1 ? 1 : 2 ,Math.min(maxNumLines,numWords));
    var remNumWords=numWords-numLines;
    
    var x=boxX;
    if (alignment === 'center') x=boxX+boxW/2;
    if (alignment === 'right') x=boxX+boxW;

    var y=boxY;


    if (direction==="down") context.textBaseline = 'hanging';
    else if (direction==="up") {
        context.textBaseline = 'bottom';
        y=boxY+boxH-(numLines-1)*fontSize;
    } else {
        context.textBaseline = 'middle';
        y=boxY+ boxH/2-((numLines-1)/2)*fontSize;
    }

	console.log(words, x, y)

    for (let i=0; i < numLines; i++) {
      var addWords=randomIntFromInterval(0,remNumWords);
        if (i==numLines-1) addWords=remNumWords; 
      var tempWords=words.slice(0,addWords+1);
      context.fillText(tempWords.join(separator), x, y);
      words.splice(0, addWords+1);
      remNumWords-=addWords;
      y+=fontSize;


    }

    return numLines;
}

function loadImage(url) {
  return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
}

export default async function renderDesign(design, divEl, i,firstpass, fontComboIndex, separator) {

  const fontCombos=[
   // {id:"OswaldQuattrocento", tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},
    {id:"cyborg", tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
    {id: "LoraRoboto", tiFont: "Lora", stiFont:"Roboto", auFont:"Roboto"},
    {id: "PlayfairDisplaySourceSansPro", tiFont: "Playfair Display", stiFont:"Source Sans Pro", auFont:"Source Sans Pro"},
    {id: "Neucha", tiFont: "Neucha", stiFont:"Neucha", auFont:"Neucha"},
    {id: "News", tiFont: "News Cycle", stiFont:"News", auFont:"News"},
    {id: "lux", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},
    {id: "sketch", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},
    {id: "expletus", tiFont: "Expletus Sans", stiFont:"Hind", auFont:"Hind"},
     {id: "Bubblegum", tiFont: "Bubblegum Sans", stiFont:"Open Sans", auFont:"Open Sans"},
     {id: "Mulish", tiFont: "Mulish", stiFont:"Space Mono", auFont:"Space Mono"},
     
  ];

const themes=[
  //  {id:"white", background: "#FFFFFFFF", primaryText: "#000000FF", secondaryText: "#000000FF"},
  //  {id:"lux", background: "#FFFFFFFF", primaryText: "#1a1a1a", secondaryText: "#1a1a1a", tiFont: "Nunito Sans", stiFont:"Nunito Sans", auFont:"Nunito Sans"},


   // {id:"lightBlue",background: "#89ABE3FF", primaryText: "#FCF6F5FF", secondaryText: "#FCF6F5FF"},
    {id:"slate",background: "#272b30", primaryText: "#aaa", secondaryText: "#aaa",  tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},

   // {id:"yellow",background: "#FEE715FF", primaryText: "#101820FF", secondaryText: "#101820FF"},
    //{id:"quartz",background: "#e83283", primaryText: "#33b7e2", secondaryText: "white",  tiFont: "Oswald", stiFont:"Quattrocento", auFont:"Quattrocento"},

   // {id: "blackOrange", background: "#101820FF", primaryText: "#F2AA4CFF", secondaryText: "#F2AA4CFF"},
  //  {id: "black", background: "dark", primaryText: "white", secondaryText: "white"},
 //   {id: "black", background: "#f4b41a", primaryText: "#143d59", secondaryText: "white"},
   // {id: "black", background: "#e4e2dd", primaryText: "#6994c6", secondaryText: "#6994c6"},
    {id: "fromCanva", background: "#e6e6e6", primaryText: "#4b4646", secondaryText: "#4b4646"},

   // {id: "cyborg", background: "#060606", primaryText: "#2a9fd6", secondaryText: "white",  tiFont: "Roboto", stiFont:"Roboto", auFont:"Roboto"},
    {id: "sketch", background: "white", primaryText: "#555555", secondaryText: "#555555", tiFont: "'Cabin Sketch', cursive", stiFont:"Neucha", auFont:"Neucha"},

];



const rgbNums=[100,110,120,130,140,150,160,170,180,190,200,210,220,230,240,250];
//const r=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//const g=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//const b=rgbNums[Math.floor(Math.random()*rgbNums.length)];
//design.r=r;
//design.g=g;
//design.b=b;


//const fontCombo=fontCombos[fontComboIndex];//Math.floor(Math.random()*fontCombos.length)];//fontComboIndex];
//const fontCombo=fontCombos[Math.floor(Math.random()*fontCombos.length)];//fontComboIndex];
var canvas = document.createElement('canvas');

canvas.id = "designCanvas"+i;
canvas.width = design.canvasWidth;
canvas.height = design.canvasHeight;
canvas.className="border border-warning border-0"
//for safari
canvas.style="width: 100%;height: auto"
divEl.appendChild(canvas);

canvas = document.getElementById("designCanvas"+i);
var ctx = canvas.getContext("2d");
var background=design;
background.designId="designCanvas"+i;






/*const colorThief = new ColorThief();

const images=galleryImages.filter( (e) => e.fileName && e.fileName.slice(0,6)!=='square' && e.fileName.slice(0,8)!=='portrait');
var imgSrc=images[Math.floor(Math.random() * images.length)].fileName;
let img = await loadImage('/gallery/'+imgSrc);
const colors=colorThief.getColor(img);
console.log(colors);
background.imageWidth=img.width;
background.imageHeight=img.height;

//const colorIndex=Math.floor(Math.random()*colors.length);
const r=colors[0];
const g=colors[1];
const b=colors[2];

var theme={};
if (r+g+b > 300) {
theme=themes[0];
} else theme=themes[1];
*/

const theme=themes[Math.floor(Math.random()*themes.length)];//themeIndex];
design.theme=theme.id;
ctx.beginPath();


ctx.fillStyle=theme.background;
ctx.fillRect(0,0, background.canvasWidth, background.canvasHeight);

//console.log(img.src);


const w = canvas.width;
const h = canvas.height;

const size = Math.random()*100;
var radius = Math.random()*300; //slightly smaller than half the size
const columns = w / size;
const rows = h / size;





if (firstpass) {





  const coloursB= ["#BABFD1", "#E8E9ED","#f9d5e5","#ffeead","#96ceb4"];
  const coloursW = [ "#FF7B9C", "#607196","#feb236","#b2ad7f","#034f84"];
  const x = Math.random();
  const numElements=Math.random()*3;
  const fillStroke=Math.random();

  if (fillStroke <=0.5) {
    if (theme.id=="white") {
      ctx.fillStyle = coloursB[Math.floor(coloursB.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

    } else {
      ctx.fillStyle = coloursW[Math.floor(coloursW.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

    }
  } else {
      if (theme.id=="white") {
        ctx.strokeStyle = coloursB[Math.floor(coloursB.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];
      } else {
        ctx.strokeStyle = coloursW[Math.floor(coloursW.length * Math.random())];//"rgb("+r+","+g+","+b+")";//colours[Math.floor(colours.length * Math.random())];

      }
    }
  
  for (let c = 0; c < numElements; c++) {

    ctx.beginPath();

    if (x < 0.33) {
      radius=Math.random()*1000;
      ctx.ellipse(
        Math.random()*w,//(c + 0.5) * size, // centre of column
        Math.random()*h,//(r + 0.5) * size, // centre of row
        radius,
        radius,
        0,
        0,
        2 * Math.PI
      );
    } else if (x < 0.66 && x >= 0.33) {
      ctx.rect(Math.random()*w,Math.random()*h, Math.random()*size,Math.random()*size);
    } else {
      ctx.rect(Math.random()*w, 0, Math.random()*size, h);
    }

    if (fillStroke <=0.5) {
      ctx.fill();
    } else {
      ctx.stroke();
    }
  }  
}



	//ctx.drawImage(img, design.imgX, design.imgY, img.width/img.height*design.imgH, design.imgH);//, img.height);

  //drawImageProp(ctx,img, design.imgX, design.imgY, design.imgW, design.imgH);

//img.src='/gallery/'+imgSrc;


var ti=background.ti;//title.title.trim();

ctx.textAlign = background.tiAlign;
ctx.fillStyle=theme.primaryText;
ctx.textBaseline=background.tiDirection;
background.tiFont=background.tiFont;//fontCombo.tiFont;
background.stiFont=background.stiFont;//fontCombo.stiFont;
background.auFont=background.auFont;//fontCombo.auFont;
ctx.letterSpacing = "10px";
background.tiLineHeight=background.tiFontSize+0;
background.stiLineHeight=background.stiFontSize+0;

if (background.tiFontEffect=='regular') {
  ctx.font = background.tiFontSize+'px '+background.tiFont;
} else {
  ctx.font = background.tiFontEffect+' '+background.tiFontSize+'px '+background.tiFont;
}



//var tiNumLines=textArranger(ctx, ti, background.tiFontSize, background.tiX, background.tiY, background.tiWidth, background.tiHeight, background.tiDirection, background.tiAlign, ' ', 5);

//console.log(background.tiX, background.tiY, background.tiWidth, background.tiFontSize, background.tiDirection, background.tiAlign, background.tiHeight);


//ctx.strokeRect(background.tiX,background.tiY, background.tiWidth, background.tiHeight);
ctx.letterSpacing = "3px";

var tiLines, tiMetricWidths=[], tiMetricHeights=[], tiLineLenghts=[];

[background.tiNumLines, background.tiMaxLineWidth, tiLines, tiMetricWidths, tiMetricHeights, tiLineLenghts]=wrapText(ctx, ti, background.tiX, background.tiY, background.tiWidth, background.tiLineHeight*1.2, background.tiDirection, background.tiAlign, background.tiHeight, ' ');

background.tiStd=getStandardDeviation(tiLineLenghts);

/*var realTiH=background.tiNumLines*background.tiFontSize;

background.tiLine0=tiLines[0] ? tiLines[0] : '';
background.tiMetricWidth0=tiMetricWidths[0] ? tiMetricWidths[0] : 0;
background.tiMetricHieight0=tiMetricHeights[0] ? tiMetricHeights[0] : 0;

background.tiLine1=tiLines[1] ? tiLines[1] : '';
background.tiMetricWidth1=tiMetricWidths[1] ? tiMetricWidths[1] : 0;
background.tiMetricHieight1=tiMetricHeights[1] ? tiMetricHeights[1] : 0;


background.tiLine2=tiLines[2] ? tiLines[2] : '';
background.tiMetricWidth2=tiMetricWidths[2] ? tiMetricWidths[2] : 0;
background.tiMetricHieight2=tiMetricHeights[2] ? tiMetricHeights[2] : 0;


background.tiLine3=tiLines[3] ? tiLines[3] : '';
background.tiMetricWidth3=tiMetricWidths[3] ? tiMetricWidths[3] : 0;
background.tiMetricHieight3=tiMetricHeights[3] ? tiMetricHeights[3] : 0;


background.tiLine4=tiLines[4] ? tiLines[4] : '';
background.tiMetricWidth4=tiMetricWidths[4] ? tiMetricWidths[4] : 0;
background.tiMetricHieight4=tiMetricHeights[4] ? tiMetricHeights[4] : 0;


background.tiLine5=tiLines[5] ? tiLines[5] : '';
background.tiMetricWidth5=tiMetricWidths[5] ? tiMetricWidths[5] : 0;
background.tiMetricHieight5=tiMetricHeights[5] ? tiMetricHeights[5] : 0;*/


//ctx.letterSpacing = "5px";


var sti=background.sti;//title.subtitle.trim();

ctx.textAlign = background.stiAlign;
ctx.fillStyle=theme.secondaryText;
ctx.textBaseline=background.stiDirection;
//ctx.letterSpacing = "5px";

var stiLines=['','','','',''], stiMetricWidths=[0,0,0,0,0], stiMetricHeights=[0,0,0,0,0],  stiLineLenghts=[];

  if (sti.length > 0) {

if (background.stiFontEffect=='regular') {
  ctx.font = background.stiFontSize+'px '+background.stiFont;
} else {
  ctx.font = background.stiFontEffect+' '+background.stiFontSize+'px '+background.stiFont;
}

//var stiNumLines=textArranger(ctx, sti, background.tiFontSize/1.98, background.stiX, background.stiY, background.stiWidth, background.stiHeight, background.stiDirection, background.stiAlign, ' ', 2);
//var tempStiY=background.stiY;
//background.stiY=background.tiY+background.tiNumLines*background.tiFontSize;
//background.stiHeight=background.stiHeight-(tempStiY-background.stiY);
//ctx.strokeRect(background.stiX,background.stiY, background.stiWidth, background.stiHeight);

  [background.stiNumLines, background.stiMaxLineWidth, stiLines, stiMetricWidths,stiMetricHeights, stiLineLenghts]=wrapText(ctx, sti, background.stiX, background.stiY, background.stiWidth, background.stiLineHeight*1.2, background.stiDirection, background.stiAlign, background.stiHeight, ' ');
  background.stiStd=getStandardDeviation(stiLineLenghts);


  }
  /*var realsTiH=background.stiNumLines*background.stiFontSize;

  background.stiLine0=stiLines[0] ? stiLines[0] : '';
  background.stiMetricWidth0=stiMetricWidths[0] ? stiMetricWidths[0] : 0;

  background.stiLine1=stiLines[1] ? stiLines[1] : '';
  background.stiMetricWidth1=stiMetricWidths[1] ? stiMetricWidths[1] : 0;

  background.stiLine2=stiLines[2] ? stiLines[2] : '';
  background.stiMetricWidth2=stiMetricWidths[2] ? stiMetricWidths[2] : 0;


  background.stiLine3=stiLines[3] ? stiLines[3] : '';
  background.stiMetricWidth3=stiMetricWidths[3] ? stiMetricWidths[3] : 0;

  background.stiLine4=stiLines[4] ? stiLines[4] : '';
  background.stiMetricWidth4=stiMetricWidths[4] ? stiMetricWidths[4] : 0;

  background.stiLine5=stiLines[5] ? stiLines[5] : '';
  background.stiMetricWidth5=stiMetricWidths[5] ? stiMetricWidths[5] : 0;*/


/*var totalH=realTiH+realsTiH+background.p*design.canvasHeight/2;
background.tiY=background.canvasHeight/2-totalH/2;
background.tiHeight=realTiH;
background.stiY=background.tiY+realTiH+background.p*design.canvasHeight/2;
background.stiHeight=realsTiH;

ctx.textAlign = background.tiAlign;
ctx.fillStyle=theme.primaryText;
ctx.textBaseline=background.tiDirection;
background.tiFont=background.tiFont;//fontCombo.tiFont;
background.stiFont=background.stiFont;//fontCombo.stiFont;
background.auFont=background.auFont;//fontCombo.auFont;
//ctx.letterSpacing = "5px";

if (background.tiFontEffect=='regular') {
  ctx.font = background.tiFontSize+'px '+background.tiFont;
} else {
  ctx.font = background.tiFontEffect+' '+background.tiFontSize+'px '+background.tiFont;
}

[background.tiNumLines, background.tiMaxLineWidth, tiLines, tiMetricWidths, tiMetricHeights]=wrapText(ctx, ti, background.tiX, background.tiY, background.tiWidth, background.tiLineHeight, background.tiDirection, background.tiAlign, background.tiHeight, ' ');



ctx.textAlign = background.stiAlign;
ctx.fillStyle=theme.secondaryText;
ctx.textBaseline=background.stiDirection;
//ctx.letterSpacing = "5px";


if (background.stiFontEffect=='regular') {
  ctx.font = background.stiFontSize+'px '+background.stiFont;
} else {
  ctx.font = background.stiFontEffect+' '+background.stiFontSize+'px '+background.stiFont;
}
[background.stiNumLines, background.stiMaxLineWidth, stiLines, stiMetricWidths]=wrapText(ctx, sti, background.stiX, background.stiY, background.stiWidth, background.stiLineHeight, background.stiDirection, background.stiAlign, background.stiHeight, ' ');

*/
var au=background.au;
ctx.textAlign = background.auAlign;
ctx.fillStyle=theme.primaryText;
var auLines=['','','','',''], auMetricWidths=[0,0,0,0,0], auLineLengths=[];

  if (au.length > 0) {


if (background.auFontEffect=='regular') {
  ctx.font = background.auFontSize+'px '+background.auFont;
} else {
  ctx.font = background.auFontEffect+' '+background.auFontSize+'px '+background.auFont;
}
//ctx.strokeRect(background.auX,background.auY, background.auWidth, background.auHeight);

//var auNumLines=textArranger(ctx, au, background.auFontSize, background.auX, background.auY, background.auWidth, background.auHeight, background.auDirection, background.auAlign, ', ',20);

 [background.auNumLines, background.auMaxLineWidth, auLines, auMetricWidths, auLineLengths]=wrapText(ctx, au, background.auX, background.auY, background.auWidth, background.auFontSize*1.2, background.auDirection, background.auAlign, background.auHeight, ', ');
 background.auStd=getStandardDeviation(auLineLengths);




  }

  /*background.auLine0=auLines[0] ? auLines[0] : '';
  background.auMetricWidth0=auMetricWidths[0] ? auMetricWidths[0] : 0;
 
  background.auLine1=auLines[1] ? auLines[1] : '';
  background.auMetricWidth1=auMetricWidths[1] ? auMetricWidths[1] : 0;
 
  background.auLine2=auLines[2] ? auLines[2] : '';
  background.auMetricWidth2=auMetricWidths[2] ? auMetricWidths[2] : 0;
 
 
  background.auLine3=auLines[3] ? auLines[3] : '';
  background.auMetricWidth3=auMetricWidths[3] ? auMetricWidths[3] : 0;
 
  background.auLine4=auLines[4] ? auLines[4] : '';
  background.auMetricWidth4=auMetricWidths[4] ? auMetricWidths[4] : 0;
 
  background.auLine5=auLines[5] ? auLines[5] : '';
  background.auMetricWidth5=auMetricWidths[5] ? auMetricWidths[5] : 0;*/
//console.log(background);

return background;

}
